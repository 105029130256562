<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top _full-w pa-3">
      <v-card :loading="loading" class="radius-card">
        <div class="pa-3">
          <v-row>
            <v-col cols="6">
              Public User List
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                solo
                dense
                rounded
                append-icon="mdi-magnify"
                label="Cari nama user"
                single-line
                hide-details
                @keyup="fetchItems"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <p class="mb-0">Tampilkan</p>
              <div class="d-flex align-center">
                <v-select
                  :items="listLimit"
                  v-model="perPage"
                  dense
                  hide-details
                  outlined
                  @change="fetchItems"
                ></v-select>
                <p class="ml-1 mb-0">Data</p>
              </div>
            </v-col>
            <v-col cols="2">
              <p class="mb-0">Sortir</p>
              <div class="d-flex align-center">
                <v-select
                  :items="listSort"
                  v-model="sort"
                  item-text="nama"
                  item-value="val"
                  dense
                  hide-details
                  outlined
                  @change="fetchItems"
                ></v-select>
              </div>
            </v-col>
          </v-row>
          <div v-if="dataSet">
            <v-data-table
              :headers="headers"
              hide-default-footer
              :page.sync="page"
              :items-per-page="perPage"
              :items="dataSet"
              class="elevation-0 my-5"
            >
              <template v-slot:[`item.nama_lengkap`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar class="mr-3">
                    <img :src="item.photo ? item.photo : dummy" alt="pp" />
                  </v-avatar>
                  <div>
                    <b>{{ item.nama_lengkap }}</b>
                    <p class="small_txt mb-0">{{ item.username }}</p>
                  </div>
                </div>
              </template>
            </v-data-table>
            <div class="text-center" v-if="loading">Memuat ...</div>
            <div class="text-center pa-2">
              <v-pagination
                v-model="page"
                :total-visible="7"
                @input="fetchItems"
                :length="last_page"
              ></v-pagination>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "listUserPublic",
  computed: {
    ...mapState({
      dummy: (state) => state.dummy,
    }),
  },
  data() {
    return {
      dataSet: null,
      listLimit: [5, 10, 20, 50, 70, 100],
      listSort: [
        { nama: "Terlama", val: "" },
        { nama: "Terbaru", val: "-created_at" },
      ],
      sort: "",
      search: "",
      page: 1,
      perPage: 10,
      last_page: null,
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "NAME", value: "nama_lengkap" },
        { text: "PHONE", value: "no_hp" },
        { text: "EMAIL", value: "email" },
        { text: "LAST LOGIN", value: "last_login" },
      ],
    };
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      this.loading = true;
      this.$store
        .dispatch("admin/listUserPublic", {
          page: this.page,
          limit: this.perPage,
          find: this.search,
        })
        .then((data) => {
          console.log(data);
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
